import {createAsyncThunk} from '@reduxjs/toolkit'
import {enqueueSnackbar} from 'notistack'
import {downloadFile} from '../../../../../shared/utils/downloadFile/downloadFile'
import {RootState} from '../../../../store'
import {PriceListService} from '../../../../../api/priceList.service'
import {priceListActions} from '../../priceListSlice'
import {PriceListFileTypes} from '../../types'

interface PriceListResponse {
    file?: Blob
    filename?: string
    data?: {
        retry_after: number
    }
    status: number
}

export const PriceListGetUrlFname = (item : {
    code: number | null,
    priceListHash: string
}) =>
{
    let url_fname = null;

    if (item.code !== null)
    {
        url_fname = `${item.priceListHash}-${item.code}`;
    }
    else
    {
        url_fname = `${item.priceListHash}`;
    }

    return url_fname;
}

export const getPriceList = createAsyncThunk<void, {
    fileType: PriceListFileTypes, id: number
}>(
    'priceList/getPriceList',
    async ({fileType, id}, {getState, rejectWithValue, dispatch}) => {
        const {code, priceListHash, polling} =
            (getState() as RootState).PriceList.list[id][fileType]

        // const columnsOrder = settings.columnsOrder
        //     ? Object.values(settings.columnsOrder).join(',')
        //     : null

        try {
            const url_fname = PriceListGetUrlFname(
                {code, priceListHash}
            );

            const {status, ...response}: PriceListResponse = await PriceListService.getPriceList(
                //priceListHash,
                url_fname,
                fileType,
                //columnsOrder
            )

            if (status === 200) {
                downloadFile(response.file, response.filename)
                return
            }
            if (status === 400) {
                return
            }
            if (!polling) {
                dispatch(
                    priceListActions.setPolling({
                        fileType,
                        id,
                        retryAfter: response.data?.retry_after || 10,
                    })
                )

                enqueueSnackbar('Началась генерация прайс-листа. Пожалуйста, ожидайте', {
                    variant: 'success',
                })
            }

            return rejectWithValue('Установлен поллинг прайс-листа')
        } catch (e) {
            console.error(e)
            return rejectWithValue('priceList/getPriceList server error, see logs')
        }
    }
)
