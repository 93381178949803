import {TDXButton} from './Buttons'
import {useEffect, useRef} from "react";

const TDXForm = ({onSubmit, children, action = {}, className = ''}: {
    onSubmit?: (e: any) => void,
    children?: React.ReactNode,
    action?: Record<string, any>,
    className?: string,
}) => {
    const form = useRef(null)

    const handleSubmit = (e: any) => {
        e.preventDefault()
        onSubmit(e)
    }

    const onChangeCheckbox = (e: any) => {
        const items_checked = form.current.querySelectorAll('input[name="'+e.target.name+'"]:checked')
        if (items_checked.length > 0) {
            form.current.querySelectorAll('input[name="'+e.target.name+'"]').forEach( (x: HTMLInputElement) => x.required = false)
        } else {
            form.current.querySelectorAll('input[name="'+e.target.name+'"]').forEach( (x: HTMLInputElement) => x.required = true)
        }
    }

    useEffect(() => {
        if (form.current) {
            const checkbox_required = form.current.querySelectorAll('input[type="checkbox"]:required')

            checkbox_required.forEach((item: HTMLInputElement) => {
                item.addEventListener('change', onChangeCheckbox)
            })
        }
    }, [form])

    return (
        <form className={`tdx-form `+className} onSubmit={handleSubmit} ref={form}>
            <div className="inputs-container">{children}</div>
            <div className="submit-container">
                {Array.isArray(action) ? (
                    <div className="buttons">
                        {action.map((btn, i) => (
                            <TDXButton
                                key={i}
                                type={btn.type ?? "submit"}
                                size="medium"
                                bg={btn.btnBg ?? ((+i + 1) % 2 !== 0 ? 'transparent border' : 'black')}
                                onClick={btn.onClick}
                                disabled={btn.disabled}
                                loading={btn.loading}
                            >
                                {btn.label}
                            </TDXButton>
                        ))}
                    </div>
                ) : (
                    <TDXButton
                        type="submit"
                        size="medium"
                        bg={action.submitBg ?? 'black'}
                        disabled={action.disabled ?? false}
                        loading={action.loading ?? false}>
                        {action.label || 'Сохранить изменения'}
                    </TDXButton>
                )}
            </div>
        </form>
    )
}

export default TDXForm
