import {useLocation, useNavigate} from 'react-router-dom'
import {useEffect} from 'react'
import {useIsFirstRender} from './customHooks'
import {TDXButton} from './UI'
import {useAppSelector} from "../shared/hooks/hooks";
import * as Sentry from "@sentry/react";

const ErrorFallback = ({error, resetErrorBoundary}: {
    error?: any,
    resetErrorBoundary?: () => void
}) => {
    const isFirst = useIsFirstRender()
    const navigate = useNavigate()
    const {pathname} = useLocation()
    const {settings} = useAppSelector(state => state.General.common)

    useEffect(() => {
        if (!isFirst) {
            resetErrorBoundary()
        }
    }, [pathname])

    /*useEffect(() => {
        Sentry.captureException(error);
    })*/

    return (
        <div className="wrapper wrapper-error" style={{padding: '20px 0'}}>
            {(
                settings?.ui_settings?.debug?.value !== undefined
                ? settings?.ui_settings?.debug?.value === true
                : true
            ) ? (
                <>
                    <h3>Что-то пошло не так:</h3>
                    <pre
                        style={{
                            padding: '40px',
                            color: 'red',
                            background: '#000',
                            borderRadius: '15px',
                            borderLeft: '5px solid red',
                            whiteSpace: 'normal',
                        }}>
                        {error.message}
                    </pre>
                </>
            ):(
                <div className="error-container">
                    <h3>Мы разработали и добавили для вас что-то новое. Для продолжения корректной работы обновите, пожалуйста, страницу</h3>
                    <div className="buttons">
                        <TDXButton
                            size="large"
                            bg="red"
                            onClick={() => {
                                resetErrorBoundary()
                                navigate(0)
                            }}>
                            Обновить страницу
                        </TDXButton>
                        <TDXButton
                            size="large"
                            bg="red"
                            onClick={() => {
                                resetErrorBoundary()
                                navigate('/')
                            }}>
                            На главную
                        </TDXButton>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ErrorFallback
