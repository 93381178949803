//import BlackFridayPopup from "./BlackFridayPopup";
import TDXPopup from "./TDXPopup";
import PollPopup from "./PollPopup";
import {useAppDispatch, useAppSelector} from "../../../shared/hooks/hooks";
import {useEffect} from "react";
import {getPopups} from '../../../store/actions/GeneralActions';
import TDXPollPopupSteps from "./TDXPollPopupSteps";
import {useLocation} from "react-router-dom";

const Popups = () => {
    const dispatch = useAppDispatch()
    const isLoggedIn = useAppSelector(state => state.Auth.isLoggedIn)
    const {polls, popups} = useAppSelector(state => state.General.common)
    const {pathname} = useLocation()

    useEffect(() => {
        if (isLoggedIn) {
            dispatch<any>(getPopups())
        }
    }, [isLoggedIn])

    return (
        <>
            {/*{duty_manager?.data?.task_1042377?.need_black_friday*/}
            {/*? <BlackFridayPopup />*/}
            {/*: ''}*/}

            {popups?.length > 0 ? (
                <>
                    {popups.map(popup => (
                        <TDXPopup key={popup.id} popup={popup}/>
                    ))}
                </>
            ): ''}

            {polls?.length > 0 ? (
                <>
                    {polls.filter(p => p.is_popup).map(poll => (
                        <PollPopup poll={poll} key={poll.id} isOpenPopup={false} onClose={() => {}} />
                    ))}
                </>
            ): ''}

            {polls?.length > 0 && ['/user/orders'].includes(pathname) ? (
                <>
                    <TDXPollPopupSteps
                        index={'poll-popup-custom'}
                        showCount={3}
                        pollStep2Id={polls.filter(p => p.slug == 'delivery-service')?.[0]?.id ?? null}
                        pollCloseId={polls.filter(p => p.slug == 'delivery-service-close')?.[0]?.id ?? null}
                        pollHeader={'Все ли ваши ожидания нам удалось угадать?'}
                        pollDescription={'Расскажи, что мы можем улучшить'}
                        pollBtnText={'Внесите свою лепту!'}
                    />
                </>

            ): ''}
        </>
    )
}

export default Popups
