import {useState} from 'react'
import {Fade, IconButton, Modal, Paper} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export const useModalWindow = () => {
    const [isOpen, setOpen] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const openWindow = () => setOpen(true)
    const closeWindow = () => setOpen(false)
    const setLoadingWindow = (action: any) => setLoading(action)

    return {
        isOpen,
        isLoading,
        openWindow,
        closeWindow,
        setLoadingWindow,
    }
}

const TDXModalWindow = ({isOpen, allowClose=true, title, children, onClose, isLoading, className = ''}:{
    isOpen?: boolean,
    allowClose?: boolean,
    title?: string,
    children?: React.ReactNode,
    onClose?: () => void,
    isLoading?: boolean,
    className?: string,
}) => (
    <Modal className={`tdx-modal-window ${isLoading ? 'disable-close' : ''} ${className}`} open={isOpen} onClose={onClose}>
        <Fade in={isOpen} timeout={500}>
            <Paper className="tdx-modal-window-paper tdx-scrollbar" elevation={12}>
                {allowClose && (
                    <IconButton className="close-button" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                )}
                {title && <h1 className="title">{title}</h1>}
                {children}
            </Paper>
        </Fade>
    </Modal>
)

export default TDXModalWindow
