import TDXModalWindow, {useModalWindow} from "../TDXModalWindow";
import {useAppSelector} from "../../../shared/hooks/hooks";
import {useEffect} from "react";
import {Link} from 'react-router-dom'
import {Popup} from "../../../store/actions/GeneralActions";
import TokenService from "../../../api/token.service";

const TDXPopup = ({popup}: {popup: Popup}) => {
    const isLoggedIn = useAppSelector(state => state.Auth.isLoggedIn)
    const {isOpen, openWindow, closeWindow} = useModalWindow()
    const LCSettings = TokenService.popupSettings
    const popup_index = 'popup-'+popup.id
    const config = LCSettings.get(popup_index) ?? {}

    useEffect(() => {
        if (isLoggedIn && !config['showed']) {
            openWindow()
        }
    }, [isLoggedIn, config])

    const onClose = () => {
        LCSettings.set(popup_index, {'showed':true})
        closeWindow()
    }

    const template = (
        <div className={'popup-content'}>
            {popup?.image?.length > 0 ? (
                <img src={popup.image} alt={popup?.title} className={'bg'} />
            ): ''}
            {popup?.text?.length > 0 ? (
                <div className={'popup-text'} dangerouslySetInnerHTML={{__html: popup.text}}></div>
            ): ''}
            {popup?.is_show_more && popup?.url?.length > 0 ? (
                 <Link
                     to={popup?.url}
                     target={popup?.is_target_blank ? '_blank': ''}
                     onClick={onClose}
                     className={'tdx-button small bg-gray'}
                 >
                    Подробнее
                </Link>
            ): ''}
        </div>
    )

    return (
        <TDXModalWindow isOpen={isOpen} onClose={onClose} className={"popup close-btn-white"}>
            <div className={'popup-wrapper tdx-popup'}>
                {popup?.url?.length > 0 && !popup?.is_show_more ? (
                    <Link to={popup?.url} target={popup?.is_target_blank ? '_blank': ''} onClick={onClose}>
                        {template}
                    </Link>
                ): template}
            </div>
        </TDXModalWindow>
    )
}

export default TDXPopup
