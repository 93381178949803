import TDXModalWindow, {useModalWindow} from "../TDXModalWindow";
import {useAppSelector,useAppDispatch} from "../../../shared/hooks/hooks";
import {useState, useEffect} from "react";
import TokenService from "../../../api/token.service";
import TDXForm from "../TDXForm";
import GeneralService from "../../../api/general.service";
import {addUserAnswer} from '../../../store/actions/GeneralActions';
import {enqueueSnackbar} from "notistack";
import {TDXPageLoader} from '../../UI'

const PollPopup = ({
    poll,
    isOpenPopup,
    onClose,
    allowClose = true
}: {
    poll: any,
    isOpenPopup: boolean,
    onClose: any,
    allowClose?: boolean,
}) => {
    const dispatch = useAppDispatch()

    const isLoggedIn = useAppSelector(state => state.Auth.isLoggedIn)
    const {isOpen, openWindow, closeWindow} = useModalWindow()
    const LCSettings = TokenService.popupSettings
    const poll_key = 'poll-'+ poll['id']
    const config = LCSettings.get(poll_key) ?? {}
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        if (isLoggedIn && !config['showed'] && poll.is_popup) {
            openWindow()
        }

        if (isLoggedIn && isOpenPopup) {
            openWindow()
        }
    }, [isLoggedIn, config])

    const closePopup = () => {
        LCSettings.set(poll_key, {'showed':true})
        onClose()
        closeWindow()
    }

    const submitForm = (e: any) => {
        setLoading(true)
        GeneralService.addUserAnswer(new FormData(e.target))
            .then(data => dispatch(addUserAnswer(data)))
            .then((/*response*/) => {
                LCSettings.set(poll_key, {'answered':true})
                closePopup()
                if (poll?.success_text.length > 0) {
                    enqueueSnackbar(poll.success_text, {variant: 'success'})
                }
                setLoading(false)
            })
    }

    const autoHeight = (e: any) => {
        e.target.style.height = 0;
        e.target.style.height = (e.target.scrollHeight) + "px";
    }

    return (
        <TDXModalWindow isOpen={isOpen} allowClose={allowClose} onClose={allowClose ? closePopup: () => {}} className={`popup popup-poll close-btn-white poll-${poll.slug}`} isLoading={isLoading}>
            <div className={'popup-wrapper'}>
                <div className="poll-header" dangerouslySetInnerHTML={{__html: poll.description}}></div>
                <div className="poll-body">
                    {isLoading && <TDXPageLoader />}
                    {poll?.questions?.length>0 ? (
                        <TDXForm action={{disabled: false, label: 'Отправить'}} onSubmit={submitForm}>
                            <input name={'poll_id'} value={poll.id} type={'hidden'} />

                            {poll.questions.map((question: any, i: number) => (
                                    <div className="question" key={i}>
                                        <div className="question-title">{poll.questions.length > 1 && i+1+'. '}{question.name}</div>
                                        <div className="answer">
                                            { question.answer_type === 0 ? (
                                                <textarea name={question.id} rows={3} className="form-control" onInput={(e) => autoHeight(e)} required={question.is_required} placeholder="Введите текст..."></textarea>
                                            ) : ''}
                                            { question.answer_type === 1 ? (
                                                <>
                                                    {question.answer.map((answer: any, i_a: number) => (
                                                        <div className="input-group" key={i+'-'+i_a}>
                                                            <input name={`${question.id}[]`} value={answer.name}
                                                                   id={`question-${question.id}-${answer.id}`} required={question.is_required}
                                                                   type="checkbox" className="form-control form-checkbox"/>
                                                            <label
                                                                htmlFor={`question-${question.id}-${answer.id}`}>{answer.name}</label>
                                                        </div>
                                                    ))}
                                                </>
                                            ) : ''}
                                            { question.answer_type === 2 ? (
                                                <>
                                                    {question.answer.map((answer: any, i_a: number) => (
                                                        <div className="input-group" key={i+'-'+i_a}>
                                                            <input name={question.id} value={answer.name}
                                                                   id={`question-${question.id}-${answer.id}`}
                                                                   type="radio" required={question.is_required} className="form-control form-radio"/>
                                                            <label
                                                                htmlFor={`question-${question.id}-${answer.id}`}>{answer.name}</label>
                                                        </div>
                                                    ))}
                                                </>
                                            ) : ''}
                                            { question.answer_type === 3 ? (
                                                <select name={question.id} required={question.is_required} className="form-control form-select">
                                                    {question.answer.map((answer: any, i_a: number) => (
                                                        <option value={answer.name} key={i+'-'+i_a}>{answer.name}</option>
                                                    ))}
                                                </select>
                                            ) : ''}
                                        </div>
                                    </div>
                                ))}
                        </TDXForm>
                    ): ''}
                </div>
            </div>
        </TDXModalWindow>
    )
}

export default PollPopup
