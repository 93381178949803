import TDXModalWindow, {useModalWindow} from "../TDXModalWindow";
import {useAppSelector} from "../../../shared/hooks/hooks";
import {useEffect, useState} from "react";
import TokenService from "../../../api/token.service";
import PollPopup from "./PollPopup";
import {TDXButton} from "../Buttons";
import {useLocation} from "react-router-dom";
import * as Sentry from "@sentry/react";

const TDXPollPopupSteps = (
    {
        index,
        showCount,
        pollStep2Id,
        pollCloseId,
        pollHeader,
        pollDescription,
        pollBtnText
    } : {
        index: string,
        showCount: number,
        pollStep2Id: number,
        pollCloseId: number,
        pollHeader?: string,
        pollDescription?: string,
        pollBtnText?: string
    }
) => {
    const isLoggedIn = useAppSelector(state => state.Auth.isLoggedIn)
    const {polls} = useAppSelector(state => state.General.common)
    const {isOpen, openWindow, closeWindow} = useModalWindow()
    const {pathname} = useLocation()
    const LCSettings = TokenService.popupSettings
    const SCSettings = TokenService.sessionPopupSettings
    const [isOpenPollStep2, setOpenPollStep2] = useState(false);
    const [isOpenPollClose, setOpenPollClose] = useState(false);
    const [onTimer, setTimer] = useState(false)

    const isAnswered = () => {
        const configPollClose = LCSettings.get('poll-'+ pollCloseId) ?? {}
        const configPollStep2 = LCSettings.get('poll-'+ pollStep2Id) ?? {}

        return configPollClose['answered'] || configPollStep2['answered']
    }

    const initPopup = () => {
        const config = SCSettings.get(index) ?? {}

        if (isLoggedIn && (config['showed'] ?? 0) < showCount && !isAnswered()) {
            openWindow()
        }

        if (config['showed'] >= showCount && !isAnswered()) {
            setOpenPollClose(true)
        }
    }

    useEffect(() => {
        if (pollStep2Id > 0 && pollCloseId > 0) {
            initPopup()
        }
    }, [isLoggedIn, pathname])

    useEffect(() => {
        if (onTimer){
            const interval = setInterval(initPopup, 60000);

            return () => {
                clearInterval(interval)
                setTimer(false)
            };
        }
    }, [onTimer])

    const nextStep = () => {
        setOpenPollStep2(true)
        Sentry.captureMessage("Click btn poll popup(Step 1 -> 2)");
        closeWindow()
    }

    const onClose = () => {
        const config = SCSettings.get(index) ?? {}
        const showCount_new = (config['showed'] ?? 0) + 1

        if (showCount_new >= showCount && !isAnswered()) {
            setOpenPollClose(true)
        }

        SCSettings.set(index, {'showed': showCount_new})

        setOpenPollStep2(false)

        if (isAnswered()) {
            setOpenPollClose(false)
        }

        setTimer(!isAnswered())

        closeWindow()
    }

    const onCloseSteps = (step = '') => {
        Sentry.captureMessage("Close poll popup("+step+")");
        onClose()
    }

    return (
        <>
            <TDXModalWindow isOpen={isOpen} onClose={() => onCloseSteps('Step 1')} className={"popup popup-poll popup-poll-step close-btn-white"}>
                <div className={'popup-wrapper tdx-popup'}>
                    <div className="popup-content">
                        <div className="poll-header">{pollHeader ?? ''}</div>
                        <div className={'popup-text'}>{pollDescription ?? ''}</div>
                        <TDXButton
                            bg={'green invert'}
                            size={'medium'}
                            onClick={nextStep}
                        >
                            {pollBtnText ?? 'Подтвердить'}
                        </TDXButton>
                    </div>
                </div>
            </TDXModalWindow>
            {polls.filter(p => p.id == pollStep2Id).map(poll => (
                <PollPopup poll={poll} key={poll.id} isOpenPopup={isOpenPollStep2} onClose={() => onCloseSteps('Step 2')} />
            ))}
            {polls.filter(p => p.id == pollCloseId).map(poll => (
                <PollPopup poll={poll} key={poll.id} isOpenPopup={isOpenPollClose} allowClose={false} onClose={onClose} />
            ))}
        </>
    )
}

export default TDXPollPopupSteps